import React from 'react';
import { people } from '../components/Stories';
import Nav from '../components/Nav';

const FullStoriesPage = () => {
  return (
    <>
      <Nav />
      <div className="px-4 py-80">
        <h1 className="text-3xl font-bold mb-8 text-center text-red-900">
          All Inspiring Stories
        </h1>
        <div id="story-section" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {people.map((person) => (
            <div
              key={person.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden transition transform duration-300 hover:scale-105"
            >
              <img
                src={person.author.imageUrl}
                alt={person.title}
                className="w-full h-auto md:h-64 lg:h-72 object-cover transition-transform duration-300 hover:scale-105"
              />
              <div className="p-4">
                <h2 className="text-xl font-bold mb-2 text-red-900">
                  {person.title}
                </h2>
                <p className="text-gray-600 mb-4">{person.description}</p>
                <p className="text-sm text-gray-500">{person.author.role}</p>
                {person.detail && (
                  <p className="text-blue-600 text-sm mt-2">{person.detail}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FullStoriesPage;
