import React from 'react';
import { useNavigate } from 'react-router-dom';

// Exported constant (kept as "people" for compatibility)
export const people = [
  {
    id: 1,
    title: 'Capacity Building in Knitting – A Giri Foundation & Vardhman Group Initiative',
    href: '#',
    description:
      'In collaboration with the Vardhman Group, the Giri Foundation is empowering artisans at Purkul by enhancing their knitting skills and market understanding. This initiative focuses on refining craftsmanship, improving product quality, and integrating commercial awareness to create sustainable livelihoods. By bridging traditional skills with modern market demands, we aim to equip artisans with the tools needed for economic independence while promoting ethical and eco-friendly production.',
    date: '2023',
    // datetime: '2020-03-16',
    category: { title: 'Knitting', href: '#' },
    author: {
      name: 'Mrs. Amrit Marbaniang Burrett',
      role: 'President',
      // href: '#',
      imageUrl:
        'https://res.cloudinary.com/dxrplcn8l/image/upload/v1739600844/20221229_113521_1_svunxy.jpg',
    },
  },
  {
    id: 2,
    title: 'Empowering Artisans',
    href: '#',
    description:
      'A Space for Artisans, Community, and Conservation Nestled in Rasulpur, near Rajaji Tiger Reserve, Pukaar Sanstha is a dedicated space for empowering local artisans, fostering community engagement, and promoting sustainable livelihoods.Created to support the villages surrounding the reserve, our initiative provides training in traditional handicrafts, financial empowerment, and eco- conscious production.By blending heritage craftsmanship with conservation efforts, we ensure that every handmade product not only preserves local artistry but also supports the protection of Rajaji’s rich biodiversity.Our commitment to zero - waste practices and ethical sourcing helps artisans build self - sustaining businesses while contributing to environmental stewardship.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    category: { title: 'Training & Space', href: '#' },
    author: {
      name: 'Mrs. Amrit Marbaniang Burrett',
      role: 'President',
      href: '#',
      imageUrl:
        'https://res.cloudinary.com/dxrplcn8l/image/upload/v1739602924/20230607_104115_m7zeci.jpg',
    },
  },
  // {
  //   id: 3,
  //   title: 'Boost your conversion rate',
  //   href: '#',
  //   description:
  //     'Fulllllll',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Mrs. Amrit Marbaniang Burrett',
  //     role: 'President',
  //     href: '#',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   },
  // },
  // {
  //   id: 4,
  //   title: 'Boost your conversion rate',
  //   href: '#',
  //   description:
  //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Michael Foster',
  //     role: 'Co-Founder / CTO',
  //     href: '#',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   },
  // },
  // {
  //   id: 5,
  //   title: 'Boost your conversion rate',
  //   href: '#',
  //   description:
  //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Michael Foster',
  //     role: 'Co-Founder / CTO',
  //     href: '#',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   },
  // },
  // {
  //   id: 6,
  //   title: 'Boost your conversion rate',
  //   href: '#',
  //   description:
  //     'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
  //   date: 'Mar 16, 2020',
  //   datetime: '2020-03-16',
  //   category: { title: 'Marketing', href: '#' },
  //   author: {
  //     name: 'Michael Foster',
  //     role: 'Co-Founder / CTO',
  //     href: '#',
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  //   },
  // },
  // More posts can be added here...
];

export default function Example({ data }) {
  const navigate = useNavigate();

  // Use the passed-in data or fallback to the exported "people" constant.
  const posts = data || people;

  // Only display up to 6 cards.
  const displayedPosts = posts.slice(0, 6);

  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-semibold tracking-tight text-red-900 sm:text-5xl">
            Stories which Inspires Us
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Work we do should be shared in public to show about how we can change the way we think and shape the Future
          </p>
        </div>
        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {displayedPosts.map((post) => (
            <article
              key={post.id}
              className="flex max-w-xl flex-col items-start justify-between"
            >
              <div className="flex items-center gap-x-4 text-xs">
                <time dateTime={post.datetime} className="text-gray-500">
                  {post.date}
                </time>
                <a
                  href={post.category.href}
                  className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                >
                  {post.category.title}
                </a>
              </div>
              <div className="group relative">
                <h3 className="mt-3 text-lg leading-6 font-semibold text-red-900 group-hover:text-gray-600">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                  {post.description}
                </p>
              </div>
              <div className="relative mt-8 flex items-center gap-x-4">
                <img
                  alt=""
                  src={post.author.imageUrl}
                  className="w-10 h-10 rounded-full bg-gray-50"
                />
                <div className="text-sm leading-6">
                  <p className="font-semibold text-gray-900">
                    <a href={post.author.href}>
                      <span className="absolute inset-0" />
                      {post.author.name}
                    </a>
                  </p>
                  <p className="text-gray-600">{post.author.role}</p>
                </div>
              </div>
            </article>
          ))}
        </div>

        {/* "View More Stories" button only if there are more than 6 posts */}
        {posts.length > 1 && (
          <div className="flex justify-center mt-8">
            <button
              onClick={() => navigate('/full-stories')}
              className="py-2 px-4 rounded-3xl text-white bg-red-900 transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:bg-red-700"
            >
              View More Stories
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
