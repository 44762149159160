import React from 'react';
import gyan from '../images/26.jpg';
import himang from '../images/22.jpg';
import hindska from '../images/25.jpg'
import aina from '../images/24.jpg'

export default function Footer() {
  return (
    <div>
      {/* Footer Top Section */}
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-center text-lg leading-8 font-semibold text-gray-900">
            <i>Partners that are United Forever</i>
          </h2>
          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-4 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <a href="https://www.projectpurkul.com/"><img
              alt="Transistor"
              src="https://static.wixstatic.com/media/9dabf8_101aeaca1eac4074bac04acd13066ff4~mv2.png/v1/crop/x_0,y_46,w_1424,h_1330/fill/w_101,h_94,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/final%2520logo%2520PNG-01_edited.png"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            /></a>
            <a href="https://gyanoday.org.in/">
            <img
              alt="Reform"
              src={gyan}
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              /></a>
            <a href="https://www.hindskatrust.org/">
            <img
              alt="Tuple"
              src={hindska}
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              />
              </a>
            <a href="https://www.facebook.com/khadimissionwardha/">
            <img
              alt="SavvyCal"
              src={ himang}
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              /></a>
            <a href="https://www.projectaaina.in/">
            <img
              alt="Statamic"
              src={aina}
              width={158}
              height={48}
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              />
            </a>
            <a href="https://www.girifoundation.org/">
            <img
              alt="SavvyCal"
              src="https://www.girifoundation.org/image/logo.PNG"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              /></a>
            <a href="https://ucost.uk.gov.in/">
            <img
              alt="SavvyCal"
              src="https://ucost.uk.gov.in/sites/default/files/logo-final.png"
              width={158}
              height={48}
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              /></a>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <footer
        style={{ background: "#6e1b2a", color: "#fff" }}
        className="flex flex-col items-center text-center"
      >
        <div className="container pt-9">
          {/* Social media icons container */}
          <div className="mb-6 flex justify-center space-x-2">
          <a
              href="https://www.facebook.com/profile.php?id=61562310193408"
              type="button"
              class="rounded-full bg-transparent p-3 font-medium uppercase leading-normal text-surface transition duration-150 ease-in-out hover:bg-black focus:outline-none focus:ring-0 dark:text-white dark:hover:bg-black"
              data-twe-ripple-init>
              <span class="[&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 320 512">
                  {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --> */}
                  <path
                    d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                </svg>
              </span>
            </a>

            <a href="mailto:pukaarsanstha@gmail.com"
              type="button"
              class="rounded-full bg-transparent p-3 font-medium uppercase leading-normal text-surface transition duration-150 ease-in-out hover:bg-black focus:outline-none focus:ring-0 dark:text-white dark:hover:bg-black"
              data-twe-ripple-init>
              <span class="mx-auto [&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 488 512">
                  {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --> */}
                  <path
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                </svg>
              </span>
            </a>

            <a
              href="https://www.instagram.com/pukaarsanstha?igsh=MTN4ZmR5c2J6OWp1NQ=="
              type="button"
              class="rounded-full bg-transparent p-3 font-medium uppercase leading-normal text-surface transition duration-150 ease-in-out hover:bg-black focus:outline-none focus:ring-0 dark:text-white dark:hover:bg-black"
              data-twe-ripple-init>
              <span class="mx-auto [&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512">
                  {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --> */}
                  <path
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </span>
            </a>

            <a
              href="https://www.linkedin.com/in/amrit-burrett-85339623b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              type="button"
              class="rounded-full bg-transparent p-3 font-medium uppercase leading-normal text-surface transition duration-150 ease-in-out hover:bg-black focus:outline-none focus:ring-0 dark:text-white dark:hover:bg-black"
              data-twe-ripple-init>
              <span class="mx-auto [&>svg]:h-5 [&>svg]:w-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 448 512">
                  {/* <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --> */}
                  <path
                    d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                </svg>
              </span>
            </a>
          </div>
        </div>
        {/* Copyright Section */}
        <div className="w-full p-4 text-center">
          © 2024 Copyright{" "}
          <a href="https://tw-elements.com/">
            Pukaar Sanstha
          </a>{" "}
          <a href="www.linkedin.com/in/pranjalrawatt">
            Designed & Developed by AP enterprises
          </a>
        </div>
      </footer>
    </div>
  );
}
