import React, { createContext, useContext, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { TextField, Button, CircularProgress } from '@mui/material';
import logo from '../Assests/pukaarsans.jpg';
import '../App.css';

Modal.setAppElement('#root');

const DonationContext = createContext();

export const useDonation = () => useContext(DonationContext);

export const DonationProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formData, setFormData] = useState({
    donation: '',
    name: '',
    email: '',
    phoneno: '',
    address: ''
  });

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const openModal = () => setModalIsOpen(true);
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setIsProcessing(false);
    setFormData({ donation: '', name: '', email: '', phoneno: '', address: '' });
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const handlePayment = useCallback(async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    try {
      // Validate amount
      const amount = Number(formData.donation);
      if (amount < 10) {
        throw new Error('Minimum donation is ₹10');
      }

      // Create order
      const orderResponse = await fetch(`${serverUrl}/create-order`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ amount: amount }),
      });

      if (!orderResponse.ok) {
        const errorData = await orderResponse.json();
        throw new Error(errorData.error || 'Payment initialization failed');
      }

      const order = await orderResponse.json();

      // Initialize Razorpay
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: order.amount,
        currency: order.currency,
        order_id: order.id,
        name: 'Pukaar Sanstha',
        image: logo,
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.phoneno,
        },
        notes: {
          address: formData.address
        },
        theme: {
          color: '#6e1b2a'
        },
        handler: async (response) => {
          try {
            const verificationResponse = await fetch(`${serverUrl}/verify-payment`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                paymentId: response.razorpay_payment_id,
                amount: amount,
                ...formData
              }),
            });

            const verificationData = await verificationResponse.json();
            
            if (verificationData.success) {
              alert('Payment successful! Thank you for your donation.');
              closeModal();
            } else {
              throw new Error('Payment verification failed');
            }

          } catch (error) {
            console.error('Verification error:', error);
            alert('Payment verification failed. Please contact support.');
          }
        }
      };

      const rzp = new window.Razorpay(options);
      rzp.open();

    } catch (error) {
      console.error('Payment error:', error.message);
      alert(error.message || 'Payment failed. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  }, [formData, serverUrl, closeModal]);

  return (
    <DonationContext.Provider value={{ openModal }}>
      {children}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Donation Form"
        className="modal"
        overlayClassName="overlay"
      >
        <form onSubmit={handlePayment} className="modal-content">
          <h2 className="modal-title">Donate to Pukaar Sanstha</h2>
          <div className="modal-body">
            <TextField
              name="name"
              label="Full Name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              disabled={isProcessing}
            />
            <TextField
              name="email"
              label="Email Address"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              disabled={isProcessing}
            />
            <TextField
              name="phoneno"
              label="Phone Number"
              type="tel"
              value={formData.phoneno}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              disabled={isProcessing}
            />
            <TextField
              name="address"
              label="Full Address"
              value={formData.address}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              disabled={isProcessing}
              multiline
              rows={3}
            />
            <TextField
              name="donation"
              label="Amount (₹)"
              type="number"
              value={formData.donation}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              required
              disabled={isProcessing}
              inputProps={{ 
                min: 10,
                step: 10 
              }}
              helperText="Minimum donation ₹10"
            />
          </div>
          <div className="modal-footer">
            <Button
              type="submit"
              variant="contained"
              disabled={isProcessing}
              startIcon={isProcessing && <CircularProgress size={20} />}
              sx={{ 
                backgroundColor: '#6e1b2a',
                '&:hover': { backgroundColor: '#8a2438' },
                '&:disabled': { backgroundColor: '#cccccc' }
              }}
            >
              {isProcessing ? 'Processing...' : 'Donate Now'}
            </Button>
            <Button
              onClick={closeModal}
              variant="outlined"
              sx={{ 
                borderColor: '#6e1b2a',
                color: '#6e1b2a',
                '&:hover': { borderColor: '#8a2438' }
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
    </DonationContext.Provider>
  );
};